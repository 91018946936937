.callus{
    padding: 20px 100px;
    padding-bottom: 40px;
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient((rgb(3, 3, 3,.8)) 100%, (rgb(3, 3, 3,.5)) 100%), url('../../images/Elevatorssola.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    gap: 20px;
    span{
        font-size: 20px;
        color: #35f800;
        font-weight: 300px;
        letter-spacing: 2px;
        font-family: Roboto;
    }
    h1{
        font-size: 30px;
        color: #fff;
        letter-spacing: 2px;
        font-family: Roboto;
        font-weight: 400;
    }
    .callConnect{
        margin-top: 40px;
        display: flex;
        gap: 30px;
        .callL{
            background-color: rgb(53, 248, 0,.5);
            padding: 60px 30px;
            color:rgb(255, 255, 255);
            display: flex;
            flex-direction: column;
            font-weight: 300;
            gap:10px;
            width: 250px;
            align-items: center;
            justify-content: center;
            .callLeft{
                .icons{
                    font-size: 50px;
                }
            }
            .callRight{
                font-weight: bolder;
            }
            .callTitle{
                font-family: Roboto;
                font-weight: bold;
                font-size: 25px;
            }
        }
        .callCenter{
            background-color: rgb(255, 255, 255,.9);
            padding: 60px 30px;
            display: flex;
            flex-direction: column;
            gap:10px;
            width: 250px;
            align-items: center;
            justify-content: center;
            .callLeft{
                .icons{
                    font-size: 50px;
                }
            }
            .callRight{
                font-weight: bolder;
            }
            .callTitle{
                font-family: Roboto;
                font-weight: bold;
                font-size: 25px;
            }
        }
    }
    
}