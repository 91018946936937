.home{
    padding: 0;
    display: flex;
    flex-direction: column;
    .banner{
        position: relative;
        background: linear-gradient((rgb(3, 3, 3,.5)) 100%, (rgb(3, 3, 3,.5)) 100%), url('../../images/Elevatorssola.jpg');
        background-repeat: no-repeat;
        height: 50vh;
        background-size: cover;
        background-attachment: fixed;
        .introBanner{
            padding: 70px 150px;
            display: flex;
            gap: 20px;
            align-items: center;
            .introL{
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h1{
                    font-size: 80px;
                    color:#fff;
                    line-height: 90px;
                    font-family: Roboto;
                    letter-spacing: 3px;
                    span{
                        color: #35f800;
                    }
                }
                p{
                    color: #fff;
                    font-size: 25px;
                    margin: 0;
                    text-transform: capitalize;
                    font-family: Roboto;
                    letter-spacing: 2px;
                }
                .button{
                    margin-top: 20px;
                    button{
                        padding: 20px;
                        background-color: rgb(53, 248, 0,.5);
                        border: 0px;
                        color: #fff;
                        border-radius: 50px;
                        cursor: pointer;
                        font-weight: bolder;
                        display: flex;
                        align-items: center;
                        .icons{
                            font-size: 18px;
                            margin-left: 10px;
                        }
                    }
                }
            }
            .introR{
                flex: 1;
            }
        }
    }
}