.feature{
    padding: 60px 50px;
    display: flex;
    flex-direction: column;
    h2{
        text-align: center;
        font-size: 35px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    .featureItems{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        border: 10px solid #303030;
        .items{
            display: flex;
            flex-direction: column;
            padding: 0;
            background-color: #fff;
            flex: 1;
            img{
                width: 100%;
                height: 250px;
                object-fit: cover;
            }
            .itemTitle{
                padding: 20px 20px;
                text-align: center;
                font-weight: bold;
            }
        }
    }
}