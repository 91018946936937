.about{
    padding: 0;
    display: flex;
    flex-direction: column;
    .banner{
        position: relative;
        background: linear-gradient((rgb(3, 3, 3,.9)) 100%, (rgb(3, 3, 3,.9)) 100%), url('../../images/Elevatorssola.jpg');
        background-repeat: no-repeat;
        height: 50vh;
        background-size: cover;
        background-attachment: fixed;
        .introL{
            flex: 2;
            padding: 40px 180px;
            h1{
                font-size: 50px;
                color:#fff;
                line-height: 90px;
                font-family: Roboto;
                letter-spacing: 3px;
                text-transform: uppercase;
                span{
                    color: #35f800;
                }
            }
            p{
                color: #fff;
                font-size: 18px;
                margin: 0;
                text-transform: capitalize;
                letter-spacing: 2px;
            }
        }
    }
    .aboutBg{
        padding: 0px 150px;
        display: flex;
        gap: 20px;
        .aboutLeft{
            flex: 1;
            padding: 50px 0px;
            span{
                font-weight: bold;
            }
            h1{
                margin: 0;
                padding: 0;
                font-size: 20px;
            }
            h3{
                margin: 0;
                padding: 0;
                font-size: 18px;
            }
            p{
                font-size: 16px;
            }
            ul{
                list-style:circle;
                padding: 0;
                padding-left: 20px;
                li{
                    padding: 10px 0px;
                }
            }
        }
        .aboutRight{
            flex: 1;
            background-color: #fff;
            padding: 50px 20px;
            h1{
                margin: 0;
                padding: 0;
                font-size: 20px;
            }
            h3{
                margin: 0;
                padding: 0;
                font-size: 18px;
            }
            p{
                font-size: 16px;
            }
            ul{
                list-style:circle;
                padding: 0;
                padding-left: 20px;
                li{
                    padding: 10px 0px;
                }
            }
        }
    }
}