@media only screen and (max-width:900px) {
    .home .banner {
        position: relative;
        background-repeat: no-repeat;
        height: auto;
        background-size: cover;
        background-attachment: fixed;
    }
    .home .banner .introBanner {
        padding: 20px 20px;
        display: flex;
        gap: 0px;
        align-items: center;
        flex-direction: column;
    }
    .home .banner .introBanner .introL h1 {
        font-size: 40px;
        color: #fff;
        font-family: Roboto;
        letter-spacing: 2px;
        font-weight: 600;
        line-height: 50px;
    }
    .home .banner .introBanner .introL p {
        color: #fff;
        font-size: 20px;
        margin: 0;
        text-transform: capitalize;
        font-family: Roboto;
        letter-spacing: 2px;
    }
}