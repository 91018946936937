@media only screen and (max-width:900px) {
    .callus {
        padding: 0px 10px;
        padding-bottom: 40px;
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        gap: 20px;
    }
    .callus .callus h1 {
        font-size: 25px;
        color: #fff;
        letter-spacing: 2px;
        font-family: Roboto;
        font-weight: 400;
        text-align: center;
    }
    .callus .callConnect {
        margin-top: 40px;
        display: flex;
        gap: 30px;
        flex-direction: column;
    }
}
