.nav{
    padding: 0px 100px;
    .menu{
        background-color: rgb(53, 248, 0,.5);
        padding: 0px 0px;
        display: flex;
        gap: 30px;
        align-items: center;
        .menuLeft{
            flex:4;
            display: flex;
            .nav-link{
                list-style: none;
                li{
                text-transform: uppercase;
                font-size: 14px;
                float: left;
                padding: 0px 20px;
                letter-spacing: 2px;
                font-weight: 500;
                color: #fff;
                }
                li:hover{
                    color: rgb(187, 187, 187);
                }
            }
            .link-mobile{
                display: none;
            }
        }
        .menuRight{
            flex: 1;
            display: flex;
            gap: 15px;
            .icons{
                color: #fff;
                cursor: pointer;
                font-weight: bolder;
            }
        }
    }
}
