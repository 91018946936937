@media only screen and (max-width:900px) {
    .footer {
        padding: 50px 20px;
        background-color: #0f0f0f;
        display: flex;
        flex-direction: column;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        gap: 0px;
    }
    .footer .footerT {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #1d1d1d;
        padding-bottom: 50px;
        flex-direction: column;
        /* margin-bottom: 40px; */
    }
    .footer .footerT .subForm {
        flex: 2 1;
        width: 100%;
    }
    .footer .footerT .subForm .subscribe input {
        border: 0px;
        background-color: inherit;
        width: 65%;
    }
    .footer .footerT .subForm .subscribe {
        display: flex;
        border-radius: 50px;
        border: 1px solid #5c5b5b;
        padding: 5px 10px;
    }
    .footer .footerT .subForm .subscribe button {
        width: 35%;
        cursor: pointer;
        border: 0;
        font-size: 13px;
        font-family: Roboto;
        letter-spacing: 1px;
        border-radius: 50px;
        padding: 10px 10px;
        color: #2b2a2a;
        text-transform: uppercase;
        background-color: #ffffff;
    }
    .footer .footerB {
        display: flex;
        gap: 40px;
        color: #949393;
        flex-direction: column;
    }
    .footer .footerB .Fgallery img {
        width: 100px;
        height: 80px;
        object-fit: cover;
        margin: 3px;
    }
}
