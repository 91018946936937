@media only screen and (max-width:900px) {
    .gallery .banner {
        position: relative;
        background-repeat: no-repeat;
        height: auto;
        background-size: cover;
        background-attachment: fixed;
    }
    .gallery .banner .introL {
        flex: 2 1;
        padding: 19px 20px;
        text-align: center;
    }
    .gallery .banner .introL h1 {
        font-size: 35px;
        color: #fff;
        line-height: 90px;
        font-family: Roboto;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
    .gallery .banner .introL p {
        color: #fff;
        font-size: 15px;
        margin: 0;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    .gallery .pics {
        padding: 70px 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }
    .gallery .pics .pic img {
        width: 230px;
        height: 350px;
        object-fit: cover;
        cursor: pointer;
    }
}
