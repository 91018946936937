.gallery{
    padding: 0;
    display: flex;
    flex-direction: column;
    .banner{
        position: relative;
        background: linear-gradient((rgb(3, 3, 3,.9)) 100%, (rgb(3, 3, 3,.9)) 100%), url('../../images/Elevatorssola.jpg');
        background-repeat: no-repeat;
        height: 40vh;
        background-size: cover;
        background-attachment: fixed;
        .introL{
            flex: 2;
            padding: 40px 180px;
            h1{
                font-size: 50px;
                color:#fff;
                line-height: 90px;
                font-family: Roboto;
                letter-spacing: 3px;
                text-transform: uppercase;
                span{
                    color: #35f800;
                }
            }
            p{
                color: #fff;
                font-size: 18px;
                margin: 0;
                text-transform: capitalize;
                letter-spacing: 2px;
            }
        }
    }
    .galleryvideos{
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        .vde{
            padding: 20px;
        }
    }
    .pics{
        padding: 70px 100px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        .pic{
            padding: 0;
            overflow: hidden;
            img{
            width: 100%;
            height: 350px;
            object-fit: cover;
            cursor: pointer;
            transition: transform .5s ease;
            }
            img:hover{
                transform: scale(1.5);
            }
        }
    }
}