
.services{
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    font-family: Roboto;
    gap: 30px;
    .quality{
        text-align: center;
        span{
            color: #393a39;
            text-transform: capitalize;
            font-weight: bold;
            letter-spacing: 2px;
        }
        h1{
            font-size: 35px;
            letter-spacing: 2px;
        }
    }
    .serviceTitile{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        justify-content: center;
        gap: 20px;
        .cards{
            padding: 0;
            flex: 1;
            background-color: #fff;
            border-radius: 3px;
            overflow: hidden;
            .imagF{
                position: relative;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 350px;
                    object-fit: cover;
                }
            }
            .cardT{
                padding: 10px 30px;
                margin-bottom: 35px;
                h2{
                    font-size: 14px;
                    font-weight: lighter;
                    letter-spacing: 1px;
                    text-transform: capitalize;
                }
                button{
                    border: 1px solid #151515;
                    padding: 10px 20px;
                    color: #202020;
                    font-family: Roboto;
                    background-color: inherit;
                    font-weight: 500;
                    letter-spacing: 2px;
                }
            }
        }
    }
    .seemore{
        text-align: center;
        display: flex;
        justify-content: center;

        a{
           background-color: rgb(53, 248, 0,.7);
           padding: 15px 35px;
           border-radius: 5px;
           font-weight: lighter;
           letter-spacing: 2px;
        }
    }
}