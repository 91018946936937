.service{
    padding: 0;
    display: flex;
    flex-direction: column;
    .banner{
        position: relative;
        background: linear-gradient((rgb(3, 3, 3,.9)) 100%, (rgb(3, 3, 3,.9)) 100%), url('../../images/Elevatorssola.jpg');
        background-repeat: no-repeat;
        height: 50vh;
        background-size: cover;
        background-attachment: fixed;
        .introL{
            flex: 2;
            padding: 40px 180px;
            h1{
                font-size: 50px;
                color:#fff;
                line-height: 90px;
                font-family: Roboto;
                letter-spacing: 3px;
                text-transform: uppercase;
                span{
                    color: #35f800;
                }
            }
            p{
                color: #fff;
                font-size: 18px;
                margin: 0;
                text-transform: capitalize;
                letter-spacing: 2px;
            }
        }
    }
    .servicesFram{
        padding: 50px 150px;
        display: flex;
        flex-direction: column;
        h1{
            font-size: 40px;
            text-align: center;
            margin-bottom: 30px;
        }
        .serviceItems{
            display: flex;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px;
            .items{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                background-color: #fff;
                flex: 1;
                img{
                    width: 100%;
                    height: 350px;
                    object-fit: cover;
                }
                .itemTitle{
                    padding: 20px 20px;
                    text-align: center;
                    font-weight: bold;
                }
            }
        }
        
    }
    .serviceFeature{
        padding: 50px 150px;
        display: flex;
        gap: 20px;
        .fLeft{
            flex: 1;
        }
        .fRight{
            flex: 1;
        }
    }
}