@media only screen and (max-width:900px) {
    .top {
        padding: 0;
        background-color: #0c0c0c;
        .container {
            display: flex;
            flex-direction: column;
            padding: 5px 10px;
            .top {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;
                .topDivCenter{
                    display: flex;
                    justify-content: end;
                }
                .quote {
                    display: none;
                }
            }
        }
    }
    
    .top .container .top .topDiv .topDivLeft img{
        width: 30px;
        height: auto;
        object-fit: cover;
    }
    .top .container .top .topDiv .topDivRight{
        line-height: 18px;
    }
    .top .container .top .topDiv .topDivRight .DivRight{
        font-weight: bold;
        font-size: 20px;
        color: #fff;
        text-transform: uppercase;
    }
    .top .container .top .topDiv .topDivRight .DivLogoName{
        font-size: 10px;
    }
}
