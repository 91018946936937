.contact{
    padding: 0;
    display: flex;
    flex-direction: column;
    .banner{
        position: relative;
        background: linear-gradient((rgb(3, 3, 3,.9)) 100%, (rgb(3, 3, 3,.9)) 100%), url('../../images/Elevatorssola.jpg');
        background-repeat: no-repeat;
        height: 40vh;
        background-size: cover;
        background-attachment: fixed;
        .introL{
            flex: 2;
            padding: 40px 180px;
            h1{
                font-size: 50px;
                color:#fff;
                line-height: 90px;
                font-family: Roboto;
                letter-spacing: 3px;
                text-transform: uppercase;
                span{
                    color: #35f800;
                }
            }
            p{
                color: #fff;
                font-size: 18px;
                margin: 0;
                text-transform: capitalize;
                letter-spacing: 2px;
            }
        }
    }
    .contactbg{
        padding: 0;
        position: relative;
        .contactmap{
            height: 300px;
        }
        .contactForm{
            padding: 0px 250px;
            display: flex;
            flex-direction: column;
            .contactnumber{
                margin-bottom: 20px;
                display: flex;
                padding: 20px;
                align-items: center;
                justify-content: center;
                .contactinfor{
                    margin-left: 10px;
                    background-color: #fff;
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    font-weight: bolder;
                    .cicon{
                        padding-right: 10px;
                    }
                }
            }
            .cL{
                display: flex;
                gap: 20px;
                flex-direction: column;
                .cLtxt{
                    display: flex;
                    gap: 45px;
                    .txtFeild{
                        flex: 1;
                        input{
                            width: 100%;
                            border: 0;
                            padding: 20px;
                        }
                        textarea{
                            width: 100%;
                            border: 0;
                            padding: 20px;
                            height: 250px;
                        }
                    }
                }
                button{
                    width: 200px;
                    border: 0;
                    border-radius: 50px;
                    padding: 20px 20px;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                    background-color: #35f800;
                }
            }
        }
    }
    .footr{
        margin-top: 50px;
    }
}