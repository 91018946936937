.footer{
    padding: 90px 100px;
    background-color: #0f0f0f;
    display: flex;
    flex-direction: column;
    background: linear-gradient((rgb(3, 3, 3,.9)) 100%, (rgb(3, 3, 3,.9)) 100%), url('../../images/Elevatorssola.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    gap: 0px;
    .footerT{
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #1d1d1d;
        padding-bottom: 50px;
        margin-bottom: 40px;
        .footrlogo{
            flex: 2;
            display: flex;
            gap: 3px;
            border: 1px solid chartreuse;
            padding: 15px;
            align-items: center;
            justify-content: center;
            align-items: center;
            .logoslug{
                color: #35f800;
                font-weight: 300px;
                letter-spacing: 2px;
                font-size: 10px;
                span{
                color: #fff;
                font-family: Roboto;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 25px;
                }
            }
            
            img{
                width: 60px;
                height: auto;
            }
        }
        .subs{
            flex: 2;
            color: #fff;
            font-family: Roboto;
            letter-spacing: 2px;
            font-size: 18px;
        }
        .subForm{
            flex: 2;
            .subscribe{
                display: flex;
                border-radius: 50px;
                border: 1px solid #505050;
                padding: 5px 10px;
                input{
                    border: 0px;
                    background-color: inherit;
                    width: 70%;
                    color: #fff;
                }
                button{
                    width: 30%;
                    cursor: pointer;
                    border: 0;
                    font-size: 13px;
                    font-family: Roboto;
                    letter-spacing: 1px;
                    border-radius: 50px;
                    padding: 10px 10px;
                    color: #2b2a2a;
                    text-transform: uppercase;
                    background-color: #ffffff;;
                }
            }
        }
    }
    .footerB{
        display: flex;
        gap: 40px;
        color: #949393;
        .Fabout{
            flex: 2;
            h3{
                color: #35f800;
                font-size: 14px;
                text-transform: uppercase; 
                margin: 0;
                margin-bottom: 20px;
                padding: 0;
                font-weight: 500;
                letter-spacing: 1px;
            }
            p{
                
                font-weight: 0;
                
                letter-spacing: 1px;
                line-height: 20px;
                font-size: 14px;
            }
            .icons{
                color: #4e4d4d;
                padding-right: 10px;
            }
            .icons:hover{
                color: #fff;
            }
        }
        .qlinks{
            flex: 0.5;
            h3{
                color: #35f800;
                font-size: 14px; 
                margin: 0;
                padding: 0;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin-bottom: 15px;
            }
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                li{
                    padding: 5px 0px;
                }
                li:hover{
                    color: #35f800;
                }
            }
        }
        .contact{
            flex: 1;
            h3{
                color: #35f800;
                font-size: 14px; 
                margin: 0;
                padding: 0;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin-bottom: 15px;
            }
            ul{
                list-style: none;
                padding: 0;
                margin: 0;
                
                li{
                    padding: 5px 0px;
                    display: flex;
                    align-items:start;

                    .icons{
                        color: #35f800;
                        font-size: 18px;
                        margin-right: 10px;
                        margin-top: 3px;
                    }
                }
            }
        }
        .Fgallery{
            flex: 2;
            h3{
                color: #35f800;
                font-size: 14px; 
                margin: 0;
                padding: 0;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin-bottom: 15px;
            }
            img{
                width: 100px;
                height: 80px;
                object-fit: cover;
            }
        }
    }
    .copyright{
        color: #585858;
        font-size: 14px;
        margin-top: 40px;
        text-align: center;
    }
}