@media only screen and (max-width:900px) {
    .contact .banner {
        position: relative;
        background-repeat: no-repeat;
        height: auto;
        background-size: cover;
        background-attachment: fixed;
    }
    .contact .banner .introL {
        flex: 2 1;
        padding: 19px 20px;
        text-align: center;
    }
    .contact .banner .introL h1 {
        font-size: 35px;
        color: #fff;
        line-height: 90px;
        font-family: Roboto;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
    .contact .banner .introL p {
        color: #fff;
        font-size: 15px;
        margin: 0;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    .contact .contactbg .contactForm {
        padding: 0px 20px;
    }
    .contact .contactbg .contactForm .cL .cLtxt {
        display: flex;
        gap: 1px;
    }
    .contact .contactbg .contactForm .cL .cLtxt .txtFeild {
        flex: 1 1;
    }
    contact .contactbg .contactForm .cL .cLtxt .txtFeild input {
        width: 80%;
        border: 0;
        padding: 20px;
    }
}
