@media only screen and (max-width:900px) {
    .services {
        padding: 30px 15px;
        display: flex;
        flex-direction: column;
        font-family: Roboto;
        gap: 20px;
    }
    .services .quality h1 {
        font-size: 30px;
        letter-spacing: 2px;
    }
    .services .serviceTitile {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
    }
    .services .serviceTitile .cards .imagF img{
        width: 300px;
        height: 350px;
        object-fit: cover;
    }

}
@media screen and (min-width:901px) and (max-width:1200px) {
    
}