.aboutUs{
    padding: 20px 100px;
    display: flex;
    .Aleft{
        flex: 2;
        position: relative;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .leftg{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 20px;
            background-color: rgb(53, 248, 0,.7);
            color: #f8f8f8;
            font-size: 30px;
            font-family: Roboto;
            text-transform: uppercase;
            width: 300px;
        }
    }
    .Aright{
        flex: 1.5;
        background-color: #fff;
        padding: 50px 25px;
        line-height: 10px;
        h2{
            font-family: Roboto;
            text-transform: uppercase;
        }
        span{
            font-family: Roboto;
            text-transform: uppercase;
            color: #35f800;
        }
        p{
            line-height: 30px;
            font-size: 18px;
            color: #2f2f2f;
            font-weight: lighter;
        }
        .button{
            button{
                border: 0px;
                border-radius: 0px;
                padding: 15px 25px;
                font-size: 16px;
                background-color: #35f800;
            }
        }
    }
}