.top{
    padding: 0;
    background-color: #0c0c0c;
    .container{
        display: flex;
        flex-direction: column;
        padding: 15px 100px;
        .top{
            display:flex;
            align-items: center;
            justify-content: center;
            gap: 50px;
            .topDiv{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                flex: 1;
                gap:10px;
                border: 1px solid #35f800;
                padding: 10px 15px;
                .topDivLeft{
                    padding: 0;
                    img{
                        width: 50px;
                        height: auto;
                        object-fit: cover;
                    }
                }
                .topDivRight{
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    line-height: 25px;
                    
                    .DivRight{
                        text-transform: uppercase;
                        font-weight: bolder;
                        color: #fff;
                        font-size: 30px;
                    }
                    .DivLogoName{
                        font-weight: bold;
                        font-size: 13px;
                        color: rgb(53, 248, 0,.5);
                        text-transform: uppercase;
                    }
                }
            }
            .topDivCenter{
                display: flex;
                gap: 10px;
                flex: 1;
                align-items: center;
                .topDivLeft{
                    background-color: #fff;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .topDivRight{
                    color: #b1afaf;
                    font-size: 13px;
                    letter-spacing: 2px;
                    text-transform: capitalize;
                    font-family: Roboto;
                    span{
                        text-transform: capitalize;
                        color: #ffffff;
                        font-size: 11px;
                        line-height: 17px;
                        display: flex;
                        font-family: Roboto;
                        font-weight: 100;
                    }
                    span:hover{
                        color: #35f800;
                    }
                }
                .button{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    button{
                        border: 0px;
                        border-radius: 0px;
                        padding: 12px 35px;
                        background-color: rgb(53, 248, 0,.5);
                        font-size: 15px;
                        color: #ffffff;
                        font-weight: bold;
                        text-transform: uppercase;
                        cursor: pointer;
                        font-family: Roboto;
                        letter-spacing: 2px;
                    }
                }
            }
        }
    }
}