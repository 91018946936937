@media only screen and (max-width:900px) {
    .about .banner {
        position: relative;
        background-repeat: no-repeat;
        height: auto;
        background-size: cover;
        background-attachment: fixed;
    }
    .about .banner .introL {
        flex: 2 1;
        padding: 20px 19px;
        text-align: center;
    }
    .about .banner .introL h1 {
        font-size: 35px;
        color: #fff;
        line-height: 90px;
        font-family: Roboto;
        letter-spacing: 3px;
        text-transform: uppercase;
        margin: 0px;
        padding: 0px;
    }
    .about .banner .introL p {
        color: #fff;
        font-size: 15px;
        margin: 0;
        text-transform: capitalize;
        letter-spacing: 2px;
    }
    .about .aboutBg {
        padding: 0px 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
}
