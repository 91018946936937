@media only screen and (max-width:900px) {
    .feature {
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
    }
    .feature .featureItems {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        .items{
            display: flex;
            flex-direction: column;
            padding: 0;
            background-color: #fff;
            flex: 1;
            img{
                width: 250px;
                height: 250px;
                object-fit: cover;
            }
            .itemTitle{
                padding: 20px 20px;
                text-align: center;
                font-weight: bold;
            }
        }
    }
}
