@media only screen and (max-width:900px) {
    .banner{
        .nav {
            padding: 0px 10px;
        }
        .nav .menu .menuRight .icons{
            display: none;
        }
    }
    .nav .menu .menuLeft ul {
        list-style: none;
        display: none;
    }
    .banner .nav .menu .menuLeft .link-mobile {
        display: block;
        position: absolute;
        right: 20px;
        top: 7px;
        background-color: inherit;
        color: #fff;
        border: 0;
        font-weight: bold;
        font-size: 30px;
    }
    .banner .nav .menu .menuLeft .mobile-link{
        position: absolute;
        display: block;
        width: 100%;
        left: 0;
        background-color: rgba(26, 26, 26, 0.8);
        top: -10px;
        padding: 25px;
        color: #fff;
        font-size: 20px;
        li{
            list-style: none;
            text-decoration: none;
            padding: 10px;
            border-bottom: 1px solid #2d2d2d;
        }
    }
    
}