@font-face {
  font-family: "Comfortaa";   /*Can be any text*/
  src: local("Comfortaa-VariableFont_wght"),
  url('../src/fonts/Comfortaa-VariableFont_wght.ttf') format("truetype");
}
@font-face {
  font-family: "Anton";   /*Can be any text*/
  src: local("Anton-Regular"),
  url('../src/fonts/Anton-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Roboto";   /*Can be any text*/
  src: local("Roboto-Bold"),
  url('../src/fonts/Roboto-Bold.ttf') format("truetype");
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e7e6e6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1{
  margin: 0;
  padding: 0;
}
.links{
  text-decoration: none;
  color: inherit;
}
*:focus {
  outline: none;
}
textarea:focus, input:focus{
  outline: none;
}
